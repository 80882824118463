body {
  background-color: #fff !important;
}

.popupCloseButton {
  box-shadow: none;
  margin-left: 10px;
  text-transform: uppercase;
  color: #344767;
  box-shadow: none;
  border: solid 1px #344767;
  transition: 0.5s;
  padding: 0 15px;
  border-radius: 4px;
}
.popupCloseButton:hover {
  background-color: #344767;
  color: #fff;
}

.popupLabel {
  font-size: 20px;
  text-align: left;
  display: flex;
  margin-bottom: 15px;
}
.popupLabel span {
  margin-right: 10px;
}

.popupInfoContainer h2 {
  font-size: 28px !important;
}

.popupButtonEdit {
  position: absolute;
  right: 0;
  top: 0;
}

.popupInfoTitleContainer {
  text-align: left;
  display: flex;
  line-height: 26px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px #dedede;
}
.popupInfoTitleContainer span {
  margin-right: 10px;
}

.popupExtraInfo {
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px #dedede;
}

select.selectDefault {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance:none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  cursor: pointer;
}

.headerCustomersDropdown {
  background-color: transparent;
  font-size: 18px;
  color: #7b809a;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: solid 1px #7b809a;
  padding-bottom: 2px;
  outline: none;
}

.clientsViewArrayContainer {
  display: flex;
  margin-top: -10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.clientsViewArrayElement {
  margin-right: 10px;
  padding: 0px 5px 0px 15px;
  font-size: 15px;
  border: solid 1px #dedede;
  border-radius: 20px;
  margin-bottom: 5px;
}

.clientInputArrayButton {
  position: absolute;
  right: 5px;
  top: 5px;
}

.customDropdownContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 44px;
  z-index: 1;
  background-color: #fff;
  border: solid 1px #dedede;
  border-radius: 0;
}

.customDropdownClient {
  color: #000;
  background-color: #fff;
  transition: 0.3s;
  font-size: 15px;
  padding: 10px;
  border-bottom: solid 1px #dedede;
}
.customDropdownClient:hover,
.customDropdownClient.selected {
  background-color: #dedede;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.pagination-inner {
  display: flex;
}

.pagination {
  padding: 15px 0;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
}

.pagination.active {
  border-radius: 50%;
  background-color: #344767;
  color: #fff;
  cursor: default;
}

.pagination-separator {
  padding: 10px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
}

.pagination_for_table_numbers .pagination {
  font-size: 13px;
  padding: 5px;
  line-height: 15px;
  width: 25px;
  height: 25px;
}

.pagination_for_table_numbers {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #344767;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

button.emply-label-button {
  border: none;
  background-color: transparent;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1.625;
  color: #344767;
}

.table_users_list button.emply-label-button {
  font-size: 14px;
  line-height: 20px;
}

.detail-domain-single {
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0;
}
.detail-domain-single.even {
  background-color: #eeeeee;
}

.file-list-folder-label {
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
}

.file-list-folder-icon-label-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-list-folder-children-container {
  margin-left: 45px;
}
.file-list-folder-children-container .file-list-folder-row {
  position: relative;
}
.file-list-folder-children-container .file-list-folder-row:before {
  position: absolute;
  left: -30px;
  content: "↵";
  transform: rotateY(180deg);
}

.choice-view-tabel-option {
  position: relative;
  padding-left: 20px;
}

.input-checkbox {
  margin-bottom: 30px;
  max-width: 400px;
}
.input-checkbox label {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  line-height: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input-checkbox.error label {
  color: var(--red-color);
}
.input-checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #ccc;
  transition: 0.5s;
  border-radius: 3px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.input-checkbox label input:checked ~ .checkmark:after {
  display: block;
}
.input-checkbox label .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 13px;
  border: solid #344767;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.stealer-details-container {
  margin-top: 50px;
  column-count: 2;
}

.stealer-details-notes {
  margin-top: 50px;
  font-size: 15px;
}

.worldmap__figure-container {
  display: flex;
  justify-content: center;
}

.map-heat-color-legend {
  /* background: linear-gradient(90deg, rgba(0, 0, 255, 0) 0%, rgba(0, 0, 255, 1) 20%, rgba(0, 128, 0, 0.2) 25%, rgba(0, 128, 0, 1) 45%, rgba(255, 255, 0, 0.2) 55%, rgba(255, 255, 0, 1) 70%, rgba(255, 0, 0, 0.2) 80%, rgba(255, 0, 0, 1) 100%); */
  /* background: linear-gradient(90deg, rgba(59, 64, 129, 0) 0%, rgba(59, 64, 129, 1) 8%, rgba(73, 119, 180, 0.6) 11%, rgba(73, 119, 180, 1) 18%, rgba(111, 164, 205, 0.6) 21%, rgba(111, 164, 205, 1) 28%, rgba(176, 217, 233, 0.6) 31%, rgba(176, 217, 233, 1) 38%, rgba(232, 247, 224, 0.6) 41%, rgba(232, 247, 224, 1) 48%, rgba(254, 236, 164, 0.6) 51%, rgba(254, 236, 164, 1) 58%, rgba(250, 154, 92, 0.6) 61%, rgba(250, 154, 92, 1) 68%, rgba(238, 101, 62, 0.6) 71%, rgba(238, 101, 62, 1) 78%, rgba(210, 48, 42, 0.6) 81%, rgba(210, 48, 42, 1) 88%, rgba(172, 11, 39, 0.6) 91%, rgba(172, 11, 39, 1) 100%); */
  background: linear-gradient(270deg, rgba(59, 64, 129, 1) 0%, rgba(73, 119, 180, 0.9) 20%, rgba(111, 164, 205, 0.8) 40%, rgba(176, 217, 233, 0.7) 60%, rgba(232, 247, 224, 0.6) 80%, rgba(254, 236, 164, 0.5) 100%);
  height: 30px;
  /* width: calc(100% - 60px); */
  width: 100%;
  margin: 10px auto;
}

.table_numbers_row {
  width: 100%;
  display: inline-block;
  border-bottom: solid 1px #344767;
  color: #344767;
  transition: 0.5s;
  font-size: 16px;
}
.table_numbers_row:hover {
  background-color: #f0f0f0;
}
.table_numbers_row.intro_row {
  font-weight: bold;
}
.table_numbers_row.intro_row:hover {
  background-color: #fff;
}
.table_numbers_row_label,
.table_numbers_row_value {
  width: 50%;
  float: left;
  text-align: left;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* react date time */
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }
  .rdt input.form-control {
    border: solid 1px #d2d6da;
    background-color: transparent;
    border-radius: 0.375rem;
    float: none;
    box-shadow: none;
    line-height: 1.4375em;
    display: block;
    padding: 0 10px;
    color: #495057;
    height: 44px;
    font-size: 14px;
  }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #FFFFFF;
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent; }
  .rdtPicker:after {
    border-bottom: .4em solid #FFFFFF;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #FFFFFF !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #344767 !important;
    color: #FFFFFF;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4); }

.rdtDays tr .dow {
  border-bottom: 1px solid #E3E3E3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #344767; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #344767;
  border-radius: 50%;
  border: 1px solid #344767; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eeeeee;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #344767 !important;
      color: #FFFFFF; }

.tabs-view-container {
  /* border-bottom: solid 1px #344767; */
  width: fit-content;
  margin-bottom: 20px;
}

.tabs-view-container button.emply-label-button {
  margin: 0;
  padding: 15px 20px;
  border-bottom: solid 1px #344767;
}
.tabs-view-container button.emply-label-button.tab-button-selected {
  border-radius: 8px 8px 0 0;
  border-top: solid 1px #344767;
  border-left: solid 1px #344767;
  border-right: solid 1px #344767;
  border-bottom: none;
  font-weight: bold;
}

.notification-number-in-header {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: red;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.detailsStealerTable {
  /* column-count: 2; */
}

.detailsStealerTableRow {
  border: solid 1px #344767;
  padding: 0px 15px;
  /* border-bottom: 0; */
  display: inline-block;
  width: 100%;
  margin-bottom: -10px;
}
.detailsStealerTableRow span {
  float: left;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 10px;
}
.detailsStealerTableRow span.label-cell {
  width: 40%;
}
.detailsStealerTableRow span.elem-cell {
  width: 30%;
  text-align: right;
  border-left: solid 1px #344767;
}

.autocomplete-container {
  width: 100%;
  position: relative;
}

.autocomplete-list-container {
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  z-index: 1;
  max-height: 500px;
  overflow-y: auto;
  display: block;
  border: solid 1px #495057;
  border-radius: 5px;
  background-color: #fff;
}

button.autocomplete-list-option {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  border-bottom: solid 1px #495057;
  padding: 10px;
  font-size: 15px;
  color: #344767;
  transition: 0.5s;
}
button.autocomplete-list-option:hover,
button.autocomplete-list-option.option-selected {
  font-weight: bold;
  background-color: #f0f0f0;
}

@media(max-width: 767px) {
  .stealer-details-container {
    column-count: 1;
  }
}
